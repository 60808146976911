import {AddAccount} from "@/Api/Money";
import Storage from "@/util/Storage";
import {AddAccountType} from "@/Api/Money/indexType";
import {Toast} from "vant";
import {filterSelect} from "@/views/Money/AddAccount/util";
import {setUpDateAccount} from "@/views/Money/Server";


export function setAddAccount(That:any) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    let upData:AddAccountType = {
        name:"",
        idCard:"",
        phone:"",
        account:"",
        accountType:filterSelect(That.typeList),
        defaultType:filterSelect(That.defaultList),
        remark:That.message,
    }

    if ( That["account"] && That["account"].length ){
        upData["account"] = That["account"]
    }else{
        Toast("请输入提现账号")
        return Promise.reject()
    }
    if ( That["name"] && That["name"].length ){
        upData["name"] = That["name"]
    }else{
        Toast("请输入账号姓名")
        return Promise.reject()
    }
    if ( That["ident"] && That["ident"].length ){
        upData["idCard"] = That["ident"]
    }else{
        Toast("请输入身份证号")
        return Promise.reject()
    }
    if ( That["phone"] && That["phone"].length ){
        upData["phone"] = That["phone"]
    }else{
        Toast("请输入手机号号")
        return Promise.reject()
    }

    Toast.loading({ message:"添加中~~~",duration:0 })
    return Promise.resolve(AddAccount({ userId,token },upData).then(res=>{
        Toast.clear()
        Toast("添加成功")
        return res.data
    }))
}

// 修改账户
export function updateAccount(That:any,oldData:any) {
    let upData:any = {
        account: That.account,
        accountType:filterSelect(That.typeList),
        defaultType:filterSelect(That.defaultList),
        idCard: That.ident,
        name: That.name,
        phone: That.phone,
        remark: That.message
    }
    upData["id"] = oldData["id"]
    let keyArr = Object.keys(upData),num = 0;
    keyArr.forEach((item,index)=>{
        if ( upData[item] != oldData[item] ){
            num ++;
        }
    })
    if ( num < 1 ){
        Toast("请修改账户信息")
        return Promise.reject()
    }
    Toast.loading({ message:"修改中~~~",duration:0 })
    return Promise.resolve(setUpDateAccount(upData).then(res=>{
        Toast.clear()
        Toast("修改成功")
        return res
    }))
}
