


export function filterList(list:any[] = [],key = 0) {
    return list.length && list.map((item,index)=>{
        if ( index == key ){
            item["active"] = true
        }else{
            item["active"] = false
        }
        return item
    }) || []
}

export function filterSelect( list:any=[] ) {
    let state = 1
    list.length && list.forEach((item:any,index:number)=>{
        if ( item.active ) state = item.state;
    })
    return state
}
