


export const keyArr = ["account","name","ident","phone",]


export const typeList = [
    {
        icon:require("@/views/Money/Img/wx.png"),
        title:"微信",
        active:true,
        state:"1"
    },{
        icon:require("@/views/Money/Img/zfb.png"),
        title:"支付宝",
        active:false,
        state:"2"
    },
]

export const defaultList = [
    {
        Icon:require("@/assets/icon/Coupon/CardMark.png"),
        NIcon:require("@/assets/icon/Coupon/CardNotMark.png"),
        active:false,
        value:"默认",
        state:"0"
    },{
        Icon:require("@/assets/icon/Coupon/CardMark.png"),
        NIcon:require("@/assets/icon/Coupon/CardNotMark.png"),
        active:true,
        value:"不默认",
        state:"1"
    },
]
